import type { NextPage } from "next";
import NewSafe from "../components/welcome/NewSafe";
import Head from "next/head";
import Script from "next/script"

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Tonkey</title>
      </Head>
      <NewSafe />
      {/* Google tag (gtag.js) */}
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-02011RTK6N"></Script>
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-02011RTK6N');
        `}
      </Script>
    </>
  );
};

export default Home;
